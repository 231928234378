export const routes = [
  {
    path: '/',
    name: 'default-layout',
    component: () => import('/var/www/staging-person.ainsights.nl/src/pages/index.vue'),
    children: [
      {
        path: '',
        name: 'main-dashboard',
        component: () => import('/var/www/staging-person.ainsights.nl/src/pages/index/index.vue'),
        /* no children */
      },
      {
        path: 'absence-history',
        /* internal name: '//absence-history' */
        /* no component */
        children: [
          {
            path: '',
            name: '//absence-history/',
            component: () => import('/var/www/staging-person.ainsights.nl/src/pages/index/absence-history/index.vue'),
            /* no children */
          },
          {
            path: ':absenceHistory',
            /* internal name: '//absence-history/[absenceHistory]' */
            /* no component */
            children: [
              {
                path: 'view',
                name: '//absence-history/[absenceHistory]/view',
                component: () => import('/var/www/staging-person.ainsights.nl/src/pages/index/absence-history/[absenceHistory]/view.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'action-list',
        /* internal name: '//action-list' */
        /* no component */
        children: [
          {
            path: '',
            name: '//action-list/',
            component: () => import('/var/www/staging-person.ainsights.nl/src/pages/index/action-list/index.vue'),
            /* no children */
          },
          {
            path: ':actionList',
            /* internal name: '//action-list/[actionList]' */
            /* no component */
            children: [
              {
                path: 'task-list',
                /* internal name: '//action-list/[actionList]/task-list' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//action-list/[actionList]/task-list/',
                    component: () => import('/var/www/staging-person.ainsights.nl/src/pages/index/action-list/[actionList]/task-list/index.vue'),
                    /* no children */
                  },
                  {
                    path: ':taskList',
                    /* internal name: '//action-list/[actionList]/task-list/[taskList]' */
                    /* no component */
                    children: [
                      {
                        path: 'execute',
                        name: '//action-list/[actionList]/task-list/[taskList]/execute',
                        component: () => import('/var/www/staging-person.ainsights.nl/src/pages/index/action-list/[actionList]/task-list/[taskList]/execute.vue'),
                        /* no children */
                      },
                      {
                        path: 'view',
                        name: '//action-list/[actionList]/task-list/[taskList]/view',
                        component: () => import('/var/www/staging-person.ainsights.nl/src/pages/index/action-list/[actionList]/task-list/[taskList]/view.vue'),
                        /* no children */
                      }
                    ],
                  }
                ],
              }
            ],
          }
        ],
      },
      {
        path: 'appointments',
        /* internal name: '//appointments' */
        /* no component */
        children: [
          {
            path: '',
            name: '//appointments/',
            component: () => import('/var/www/staging-person.ainsights.nl/src/pages/index/appointments/index.vue'),
            /* no children */
          },
          {
            path: ':appointment',
            /* internal name: '//appointments/[appointment]' */
            /* no component */
            children: [
              {
                path: 'view',
                name: '//appointments/[appointment]/view',
                component: () => import('/var/www/staging-person.ainsights.nl/src/pages/index/appointments/[appointment]/view.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'chat',
        /* internal name: '//chat' */
        /* no component */
        children: [
          {
            path: '',
            name: '//chat/',
            component: () => import('/var/www/staging-person.ainsights.nl/src/pages/index/chat/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'contacts',
        /* internal name: '//contacts' */
        /* no component */
        children: [
          {
            path: '',
            name: '//contacts/',
            component: () => import('/var/www/staging-person.ainsights.nl/src/pages/index/contacts/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'documents',
        /* internal name: '//documents' */
        /* no component */
        children: [
          {
            path: '',
            name: '//documents/',
            component: () => import('/var/www/staging-person.ainsights.nl/src/pages/index/documents/index.vue'),
            /* no children */
          },
          {
            path: ':document',
            /* internal name: '//documents/[document]' */
            /* no component */
            children: [
              {
                path: 'view',
                name: '//documents/[document]/view',
                component: () => import('/var/www/staging-person.ainsights.nl/src/pages/index/documents/[document]/view.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'upload',
            name: '//documents/upload',
            component: () => import('/var/www/staging-person.ainsights.nl/src/pages/index/documents/upload.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'employee-benefits',
        /* internal name: '//employee-benefits' */
        /* no component */
        children: [
          {
            path: '',
            name: '//employee-benefits/',
            component: () => import('/var/www/staging-person.ainsights.nl/src/pages/index/employee-benefits/index.vue'),
            /* no children */
          },
          {
            path: ':employeeBenefit',
            /* internal name: '//employee-benefits/[employeeBenefit]' */
            /* no component */
            children: [
              {
                path: 'items',
                /* internal name: '//employee-benefits/[employeeBenefit]/items' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'employee-benefit-items',
                    component: () => import('/var/www/staging-person.ainsights.nl/src/pages/index/employee-benefits/[employeeBenefit]/items/index.vue'),
                    /* no children */
                  },
                  {
                    path: ':item',
                    /* internal name: '//employee-benefits/[employeeBenefit]/items/[item]' */
                    /* no component */
                    children: [
                      {
                        path: 'book-appointment',
                        name: 'employee-benefit-appointment',
                        component: () => import('/var/www/staging-person.ainsights.nl/src/pages/index/employee-benefits/[employeeBenefit]/items/[item]/book-appointment.vue'),
                        /* no children */
                      }
                    ],
                  }
                ],
              }
            ],
          }
        ],
      },
      {
        path: 'employment',
        /* internal name: '//employment' */
        /* no component */
        children: [
          {
            path: '',
            name: '//employment/',
            component: () => import('/var/www/staging-person.ainsights.nl/src/pages/index/employment/index.vue'),
            /* no children */
          },
          {
            path: ':employment',
            /* internal name: '//employment/[employment]' */
            /* no component */
            children: [
              {
                path: 'view',
                name: '//employment/[employment]/view',
                component: () => import('/var/www/staging-person.ainsights.nl/src/pages/index/employment/[employment]/view.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'meeting',
        /* internal name: '//meeting' */
        /* no component */
        children: [
          {
            path: ':meeting',
            /* internal name: '//meeting/[meeting]' */
            /* no component */
            children: [
              {
                path: '',
                name: '//meeting/[meeting]/',
                component: () => import('/var/www/staging-person.ainsights.nl/src/pages/index/meeting/[meeting]/index.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'notifications',
        /* internal name: '//notifications' */
        /* no component */
        children: [
          {
            path: '',
            name: '//notifications/',
            component: () => import('/var/www/staging-person.ainsights.nl/src/pages/index/notifications/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'person-information',
        name: '//person-information',
        component: () => import('/var/www/staging-person.ainsights.nl/src/pages/index/person-information.vue'),
        /* no children */
      },
      {
        path: 'settings',
        /* internal name: '//settings' */
        /* no component */
        children: [
          {
            path: '',
            name: '//settings/',
            component: () => import('/var/www/staging-person.ainsights.nl/src/pages/index/settings/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'tasks',
        /* internal name: '//tasks' */
        /* no component */
        children: [
          {
            path: '',
            name: '//tasks/',
            component: () => import('/var/www/staging-person.ainsights.nl/src/pages/index/tasks/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'timeline',
        /* internal name: '//timeline' */
        /* no component */
        children: [
          {
            path: '',
            name: '//timeline/',
            component: () => import('/var/www/staging-person.ainsights.nl/src/pages/index/timeline/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'webshop',
        /* internal name: '//webshop' */
        /* no component */
        children: [
          {
            path: '',
            name: '//webshop/',
            component: () => import('/var/www/staging-person.ainsights.nl/src/pages/index/webshop/index.vue'),
            /* no children */
          },
          {
            path: ':webshop',
            /* internal name: '//webshop/[webshop]' */
            /* no component */
            children: [
              {
                path: 'items',
                /* internal name: '//webshop/[webshop]/items' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'webshop-items',
                    component: () => import('/var/www/staging-person.ainsights.nl/src/pages/index/webshop/[webshop]/items/index.vue'),
                    /* no children */
                  },
                  {
                    path: ':item',
                    /* internal name: '//webshop/[webshop]/items/[item]' */
                    /* no component */
                    children: [
                      {
                        path: 'book-appointment',
                        name: 'webshop-appointment',
                        component: () => import('/var/www/staging-person.ainsights.nl/src/pages/index/webshop/[webshop]/items/[item]/book-appointment.vue'),
                        /* no children */
                      }
                    ],
                  }
                ],
              }
            ],
          }
        ],
      }
    ],
    meta: {
      "requiresAuth": true
    }
  },
  {
    path: '/:all(.*)',
    name: '404',
    component: () => import('/var/www/staging-person.ainsights.nl/src/pages/[...all].vue'),
    /* no children */
    meta: {
      "requiresAuth": false
    }
  },
  {
    path: '/auth',
    name: '/auth',
    component: () => import('/var/www/staging-person.ainsights.nl/src/pages/auth.vue'),
    children: [
      {
        path: 'forgot-password',
        name: '/auth/forgot-password',
        component: () => import('/var/www/staging-person.ainsights.nl/src/pages/auth/forgot-password.vue'),
        /* no children */
      },
      {
        path: 'login',
        name: 'auth-login',
        component: () => import('/var/www/staging-person.ainsights.nl/src/pages/auth/login.vue'),
        /* no children */
      },
      {
        path: 'register',
        name: '/auth/register',
        component: () => import('/var/www/staging-person.ainsights.nl/src/pages/auth/register.vue'),
        /* no children */
      },
      {
        path: 'reset-password',
        name: '/auth/reset-password',
        component: () => import('/var/www/staging-person.ainsights.nl/src/pages/auth/reset-password.vue'),
        /* no children */
      },
      {
        path: 'verify-email',
        name: '/auth/verify-email',
        component: () => import('/var/www/staging-person.ainsights.nl/src/pages/auth/verify-email.vue'),
        /* no children */
      }
    ],
    meta: {
      "requiresAuth": false
    }
  }
]
